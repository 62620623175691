$primary: #2A2A2A;
$secondary: #dcff00;
$enable-shadows: true;

@import '~bootstrap/scss/bootstrap.scss';

@font-face {
  font-family: "manrope";
  src: local("Manrope-VariableFont_wght"),
    url("../fonts/Manrope-VariableFont_wght.ttf") format("truetype");
  font-weight: 200 800;
}

@font-face {
  font-family: "cabin";
  src: local("Cabin-VariableFont_wdth,wght"),
    url("../fonts/Cabin-VariableFont_wdth,wght.ttf") format("truetype");
}
@font-face {
  font-family: "cabin-italic";
  src: local("Cabin-Italic-VariableFont_wdth,wght"),
    url("../fonts/Cabin-Italic-VariableFont_wdth,wght.ttf") format("truetype");
}

:root {
  --bs-body-font-family: 'manrope', sans-serif;
}