@import 'custom-bootstrap';

.brand-logo {
  font-family: "cabin";
  font-weight: 700;
}
.brand-logo a {
	text-decoration: none;
	color: $primary;
	font-size: 1.5rem;
}
.brand-logo a:hover {
	color: $primary;
	transition: 0.25s ease;
}